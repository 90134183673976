import React from "react"
import ReactDOM from "react-dom/client"
import "core-js/actual"
import {unregister} from "./registerServiceWorker"
import {BrowserRouter} from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import {Provider as ReduxProvider} from "react-redux"
import {ScrollToTop} from "react-router-scroll-to-top"
import CssBaseline from "@mui/material/CssBaseline"
import {ThemeProvider} from "@mui/material/styles"
import {createTheme, StyledEngineProvider} from "@mui/material/styles"
import SnackbarProvider from "components/SnackbarProvider"
import App from "./App"
import store from "./redux/store"
import "styles/global.scss"
import "fonts/SanFrancisco/stylesheet.css"

unregister()

const theme = createTheme({
	palette: {
		primary: {
			main: "#5e0fec",
		},
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
	shape: {
		borderRadius: 18,
	},
	typography: {
		useNextVariants: true,
		fontSize: 14,
		fontFamily: `"SF UI Text", "Roboto", "Helvetica", "Arial", sans-serif`,
		h5: {
			fontSize: "1em",
			fontWeight: 500,
		},
		body1: {
			fontSize: "1em",
			fontWeight: "400",
			lineHeight: "1.6em",
			letterSpacing: ".01em",
		},
		h6: {
			fontSize: "1.2em",
			letterSpacing: "-0.02em",
			fontWeight: "500",
			lineHeight: "1.6",
		},
		subtitle1: {
			lineHeight: "1.4em",
		},
	},
	shadows: [...Array(25)].map(
		(item, index) =>
			`0 ${Math.round((index / 25) * 20)}px ${Math.round(
				2 + (index / 25) * 30
			)}px ${Math.round(((index + 1) / 25) * 2)}px rgba(0,0,0,.12)`
	),
})

ReactDOM.createRoot(document.getElementById("root")).render(
	//<React.StrictMode>
	<ReduxProvider store={store}>
		<ThemeProvider theme={theme}>
			<BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE_PATH}>
				<ScrollToTop>
					<StyledEngineProvider injectFirst>
						<SnackbarProvider>
							<CssBaseline />
							<App />
						</SnackbarProvider>
					</StyledEngineProvider>
				</ScrollToTop>
			</BrowserRouter>
		</ThemeProvider>
	</ReduxProvider>
	//</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
