import React, {useMemo} from "react"
import classnames from "classnames"
import {languageFlags} from "js/utils"
import {useSelector} from "react-redux"

import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import SelectMUI from "@mui/material/Select"

import styles from "./index.module.scss"

export const Select = ({
	value,
	onChange,
	children,
	label,
	className,
	...rest
}) => {
	return (
		<SelectMUI
			data-filled={value !== ""}
			value={value}
			onChange={onChange}
			className={classnames(styles.select, className)}
			classes={{icon: styles.icon}}
			displayEmpty
			input={
				<OutlinedInput
					classes={{
						notchedOutline: styles.notchedOutline,
					}}
				/>
			}
			{...rest}
		>
			<MenuItem value="" disabled>
				{label}
			</MenuItem>
			{children}
		</SelectMUI>
	)
}

const SubjectFilters = props => {
	const {
		course,
		setCourse,
		archived,
		setArchived,
		language,
		setLanguage,
		ordering,
		setOrdering,
		university,
		setUniversity,
		className,
		classes = {},
	} = props

	const universities = useSelector(state => state.universities)

	const selects = useMemo(
		() => [
			{
				name: "language",
				label: "Язык обучения",
				value: language,
				setValue: setLanguage,
				options: [
					{
						label: (
							<>
								<img
									src={languageFlags.kz}
									alt=""
									className={styles.flag}
								/>{" "}
								Казахский
							</>
						),
						value: "kz",
					},
					{
						label: (
							<>
								<img
									src={languageFlags.ru}
									alt=""
									className={styles.flag}
								/>{" "}
								Русский
							</>
						),
						value: "ru",
					},
					{
						label: (
							<>
								<img
									src={languageFlags.en}
									alt=""
									className={styles.flag}
								/>{" "}
								Английский
							</>
						),
						value: "en",
					},
				],
			},
			{
				name: "course",
				label: "Курс",
				value: course,
				setValue: setCourse,
				options: [
					{
						label: "1 курс",
						value: "1",
					},
					{
						label: "2 курс",
						value: "2",
					},
					{
						label: "3 курс",
						value: "3",
					},
					{
						label: "4 курс",
						value: "4",
					},
				],
			},
			{
				name: "archived",
				label: "По дате",
				value: archived,
				setValue: setArchived,
				options: [
					{
						label: "Новые",
						value: "0",
					},
					{
						label: "Архивированные",
						value: "1",
					},
				],
			},
			{
				name: "ordering",
				label: "Сортировка",
				value: ordering,
				setValue: setOrdering,
				options: [
					{
						label: "По алфавиту",
						value: "name",
					},
					{
						label: "По популярности",
						value: "used_count",
					},
					{
						label: "По дате добавления",
						value: "date",
					},
				],
			},
			{
				name: "university",
				label: "Учебное заведение",
				value: university,
				setValue: setUniversity,
				options: universities,
			},
		],
		[
			course,
			setCourse,
			archived,
			setArchived,
			language,
			setLanguage,
			ordering,
			setOrdering,
			university,
			setUniversity,
			universities,
		]
	)

	return (
		<div className={classnames(styles.root, className, classes.root)}>
			{selects.map(({name, options, label, value, setValue}) => (
				<Select
					key={name}
					value={value}
					onChange={e => setValue(e.target.value)}
					className={classnames(
						styles.selectMarginBottom,
						name !== "university" && styles.halfSelect
					)}
					label={label}
				>
					{options.map(option => (
						<MenuItem
							key={option.value}
							value={option.value}
							className={styles.menuItem}
							divider={name === "university"}
						>
							{option.label}
						</MenuItem>
					))}
				</Select>
			))}
		</div>
	)
}

export default SubjectFilters
