import React, {forwardRef} from "react"
import classnames from "classnames"
import {useSnackbar, SnackbarContent} from "notistack"

import CloseIcon from "@mui/icons-material/Close"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"

import styles from "./index.module.scss"

const SnackbarMessage = forwardRef((props, ref) => {
	const {closeSnackbar} = useSnackbar()
	const {
		id,
		title,
		content,
		message,
		variant,
		className,
		classes = {},
		...rest
	} = props

	const Icon =
		variant &&
		{
			success: CheckCircleRoundedIcon,
			error: CancelRoundedIcon,
			warning: WarningRoundedIcon,
		}[variant]

	return (
		<SnackbarContent
			ref={ref}
			className={classnames(styles.root, className, classes.root)}
			data-type={variant}
			{...rest}
		>
			<CloseIcon
				className={styles.closeIcon}
				onClick={() => closeSnackbar(id)}
			/>
			{title && <div className={styles.title}>{title}</div>}
			{message && (
				<div className={styles.message}>
					{variant && <Icon className={styles.messageIcon} />}
					{message}
				</div>
			)}
			{content}
		</SnackbarContent>
	)
})

export default SnackbarMessage
