import React, {
	Suspense,
	useState,
	useEffect,
	useMemo,
	useCallback,
	useReducer,
	lazy,
} from "react"
import {
	Route,
	Routes,
	Navigate,
	useNavigate,
	useLocation,
} from "react-router-dom"
import Helmet from "react-helmet"
import useApi from "api/useApi"
import parse from "date-fns/parse"
import {useSnackbar} from "notistack"
import useEffectOnce from "hooks/useEffectOnce"
import {setUserParams, reachGoal} from "js/ym"
import {useDispatch} from "react-redux"
import {auth, unauth} from "../redux/auth-slice"
import {setUniversities} from "../redux/universities-slice"
import {useSelector} from "react-redux"
import UserContext from "contexts/user"

import Box from "@mui/material/Box"
import Loading from "components/Loading"
import SelectSubject from "components/SelectSubject"
import Launch from "components/Launch"
import BottomNavigation from "components/BottomNavigation"
import Header from "components/Header"
import ErrorMessageBody from "components/ErrorMessageBody"
import TopProgressBar from "components/TopProgressBar"

import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded"
import FactCheckRoundedIcon from "@mui/icons-material/FactCheckRounded"
import BackupRoundedIcon from "@mui/icons-material/BackupRounded"
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"
import SearchRoundedIcon from "@mui/icons-material/SearchRounded"

import SessionPage from "pages/session/App"
import PreparePage from "pages/prepare/App"
import UploadPage from "pages/upload/App"
import CabinetPage from "pages/cabinet/App"
import SearchPage from "pages/search/App"
import BookmarksPage from "pages/bookmarks/App"
import RequestsPage from "pages/requests/App"
import TopPage from "pages/top/App"

import styles from "./index.module.scss"

const allPages = [
	{
		name: "Сессия",
		url: "/session",
		component: SessionPage,
		private: true,
	},
	{
		name: "Подготовка",
		url: "/prepare",
		component: PreparePage,
		private: true,
	},
	{
		name: "Загрузить",
		url: "/upload",
		component: UploadPage,
		private: true,
	},
	{
		name: "Профиль",
		url: "/cabinet",
		component: CabinetPage,
		private: true,
	},
	{
		name: "Поиск",
		url: "/search",
		component: SearchPage,
		private: true,
	},
	{
		name: "Закладки",
		url: "/bookmarks",
		component: BookmarksPage,
		private: true,
	},
	{
		name: "Заявки",
		url: "/requests",
		component: RequestsPage,
		private: true,
	},
	{
		name: "Рейтинг лучших",
		url: "/top",
		component: TopPage,
		private: true,
	},
	{
		name: "Восстановить пароль",
		url: "/restore",
		component: lazy(() => import("../pages/restore/App")),
		private: false,
	},
	{
		name: "Testnik.kz",
		url: "/about",
		component: lazy(() => import("../pages/about/App")),
		private: false,
	},
	{
		name: "Условия соглашения",
		url: "/terms",
		component: lazy(() => import("../pages/terms/App")),
		private: false,
	},
	{
		name: "FAQ",
		url: "/faq",
		component: lazy(() => import("../pages/faq/App")),
		private: false,
	},
	{
		name: "Testnik.kz",
		url: "/signin",
		component: lazy(() => import("../pages/signin/App")),
		private: false,
	},
	{
		name: "Testnik.kz",
		url: "/signup",
		component: lazy(() => import("../pages/signup/App")),
		private: false,
	},
]
const publicPages = allPages.filter(page => !page.private)
const privatePages = allPages.filter(page => page.private)
const initialTabs = Object.fromEntries(
	[
		{
			url: "/prepare",
			icon: PsychologyRoundedIcon,
		},
		{
			url: "/session",
			icon: FactCheckRoundedIcon,
		},
		{
			url: "/upload",
			icon: BackupRoundedIcon,
		},
		{
			url: "/cabinet",
			icon: PersonRoundedIcon,
		},
		{
			url: "/search",
			icon: SearchRoundedIcon,
		},
	].map(item => {
		const id = item.url.replace("/", "")
		return [
			id,
			{
				...item,
				id,
				scrollTop: 0,
				name: allPages.find(page => page.url === item.url).name,
			},
		]
	})
)

const App = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const {enqueueSnackbar} = useSnackbar()
	const {token, getUserInfo, getUniversities} = useApi()
	const dispatch = useDispatch()
	const isAuthorized = useSelector(state => state.isAuthorized)
	//const params = useURLParams()

	const tabsReducer = (state, newState) => {
		const {id, ...newProps} = newState

		return {
			...state,
			[id]: {
				...state[id],
				...newProps,
			},
		}
	}
	const [tabs, dispatchTabs] = useReducer(tabsReducer, initialTabs)
	const tabsValues = useMemo(() => Object.values(tabs), [tabs])
	//const tabsKeys = useMemo(() => Object.keys(tabs), [tabs])
	const [isOverscrolled, setIsOverscrolled] = useState(false)

	const [isVerifyingAuthorization, setIsVerifyingAuthorization] =
		useState(true)
	const [currentTab, setCurrentTab] = useState({})
	useEffect(
		() => setCurrentTab(tabs[location.pathname.replace("/", "")] || {}),
		[location.pathname, tabs]
	)
	const [currentPage, setCurrentPage] = useState({})
	useEffect(
		() =>
			setCurrentPage(
				allPages.find(item => item.url === location.pathname) || {}
			),
		[location.pathname]
	)
	const [nightModeEnabled, setNightModeEnabled] = useState(false)
	const [searchQuery, setSearchQuery] = useState("")
	const [isSearching, setIsSearching] = useState(false)

	const [sessionSelectedSubject, setSessionSelectedSubject] = useState(null)
	const [prepareSelectedSubject, setPrepareSelectedSubject] = useState(null)
	const currentTabSelectedSubject = useMemo(
		() =>
			({
				session: sessionSelectedSubject,
				prepare: prepareSelectedSubject,
			}[currentTab.id] || null),
		[sessionSelectedSubject, prepareSelectedSubject, currentTab.id]
	)
	const setCurrentTabSelectedSubject = useCallback(
		subject => {
			const setState = {
				session: setSessionSelectedSubject,
				prepare: setPrepareSelectedSubject,
			}[currentTab.id]
			setState && setState(subject)
		},
		[currentTab.id]
	)

	const openSessionWithSubject = useCallback(
		subject => {
			setSessionSelectedSubject(subject)
			navigate("/session")
		},
		[navigate]
	)

	const openPrepareWithSubject = useCallback(
		subject => {
			setPrepareSelectedSubject(subject)
			navigate("/prepare")
		},
		[navigate]
	)

	const [user, setUser] = useState({
		id: null,
		email: null,
		name: "Аккаунт",
		expires_at: new Date(),
		phone: null,
		//userpic: null,
		//devices: [],
		course: "",
		language: "",
		archived: 0,
		ordering: "name",
		university_id: 0,
		restores: 0,
		ui_language: "ru",
	})

	const onChangeTab = useCallback(
		newTabId => {
			currentTab.id &&
				dispatchTabs({
					id: currentTab.id,
					scrollTop: document.documentElement.scrollTop,
				})
			if (newTabId !== currentTab.id) {
				navigate(newTabId)

				/*const container = document.documentElement
				const observer = new ResizeObserver(() => {
					if (container.scrollHeight > window.innerHeight) {
						document.documentElement.scrollTop =
							tabs[newTabId].scrollTop
						observer.disconnect()
					}
				})
				observer.observe(container)
				setTimeout(() => {
					observer.disconnect()
				}, 5000)*/

				requestAnimationFrame(
					() =>
						(document.documentElement.scrollTop =
							tabs[newTabId].scrollTop)
				)
			}
		},
		[navigate, currentTab.id, tabs]
	)

	const nightModeToggle = useCallback(force => {
		const isForced = typeof force === "boolean"
		if (isForced) {
			setNightModeEnabled(force)
			window.localStorage.setItem("nightModeEnabled", force)
			reachGoal(force ? "nightmode_on" : "nightmode_off")
		} else {
			setNightModeEnabled(prevValue => {
				const newValue = !prevValue
				window.localStorage.setItem("nightModeEnabled", newValue)
				reachGoal(newValue ? "nightmode_on" : "nightmode_off")
				return newValue
			})
		}
	}, [])

	useEffect(() => {
		document.body.setAttribute(
			"data-theme",
			nightModeEnabled ? "dark" : "light"
		)
	}, [nightModeEnabled])

	useEffect(() => {
		const mediaQuery =
			window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)")
		const lsValue = window.localStorage.getItem("nightModeEnabled")
		if (lsValue) {
			setNightModeEnabled(lsValue === "true")
		} else {
			setNightModeEnabled(
				window.matchMedia &&
					window.matchMedia("(prefers-color-scheme: dark)").matches
			)
		}

		const onChangeMode = e => {
			setNightModeEnabled(e.matches)
		}

		mediaQuery?.addEventListener &&
			mediaQuery.addEventListener("change", onChangeMode)

		return () =>
			mediaQuery?.removeEventListener &&
			mediaQuery.removeEventListener("change", onChangeMode)
	}, [])

	const _getUserInfo = useCallback(async () => {
		const {status, data} = await getUserInfo()
		if (status) {
			return {
				status,
				data: {
					...data,
					expires_at: parse(
						data.expires_at,
						"yyyy-MM-dd HH:mm:ss",
						new Date()
					),
					university_id: data.university_id,
					course: data.course || "",
				},
			}
		} else {
			return {status, data: {}}
		}
	}, [getUserInfo])

	useEffect(() => {
		if (!isAuthorized) return
		;(async () => {
			try {
				const {status, data} = await _getUserInfo()
				if (status) {
					setUser(data)
					setUserParams({
						UserID: String(data.id),
						email: data.email,
					})
				}
			} catch (err) {}
		})()
	}, [isAuthorized, _getUserInfo])

	useEffect(() => {
		if (!isAuthorized) return
		;(async () => {
			try {
				const {data} = await getUniversities()
				dispatch(
					setUniversities([
						{
							label: "Все учебные заведения",
							value: 0,
						},
						...data.map(item => ({
							label: item.name,
							value: item.id,
						})),
					])
				)
			} catch (err) {
				console.error(err)
			}
		})()
	}, [isAuthorized, getUniversities, dispatch])

	useEffectOnce(() => {
		;(async () => {
			try {
				const {status, data} = await _getUserInfo()
				if (status) {
					setUser(data)
					dispatch(auth())
					if (location.pathname === "/signin") {
						navigate("/")
					}
				} else {
					dispatch(unauth())
					if (location.pathname === "/") {
						navigate(token ? "/signin" : "/about")
					}
				}
			} catch (err) {
				dispatch(unauth())
				if (location.pathname === "/") {
					navigate(token ? "/signin" : "/about")
				}
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось авторизоваться."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
			}
			setIsVerifyingAuthorization(false)
		})()
	}, [
		setUser,
		navigate,
		getUserInfo,
		token,
		location.pathname,
		enqueueSnackbar,
		dispatch,
	])

	useEffect(() => {
		let previousScroll = 0
		let translate = 0
		let previousDirection = "down"
		const upBreakpoint = 1
		const downBreakpoint = 56

		const onScroll = () => {
			const currentScroll = document.documentElement.scrollTop
			const difference = currentScroll - previousScroll
			const currentDirection = difference > 0 ? "down" : "up"
			const directionChanged = previousDirection !== currentDirection

			translate += difference

			if (directionChanged) {
				translate = 0
			}
			if (currentScroll <= downBreakpoint) {
				setIsOverscrolled(false)
			} else {
				if (translate >= downBreakpoint) {
					setIsOverscrolled(true)
				} else if (translate <= -upBreakpoint) {
					setIsOverscrolled(false)
				}
			}

			previousDirection = currentDirection
			previousScroll = currentScroll
		}

		window.addEventListener("scroll", onScroll)

		return () => window.removeEventListener("scroll", onScroll)
	}, [])

	useEffect(() => {
		const onCopy = async e => {
			e.preventDefault()
			try {
				e.clipboardData.setData(
					"text/plain",
					"Копирование контента запрещено"
				)
			} catch (err) {
				console.error(err)
			}
		}
		document.addEventListener("copy", onCopy)

		return () => document.removeEventListener("copy", onCopy)
	}, [])

	const title = currentPage.name || "Testnik.kz"

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
			}}
		>
			{isVerifyingAuthorization && <Launch />}

			<Helmet>
				<meta
					name="theme-color"
					content={nightModeEnabled ? "#202123" : "#5e0fec"}
				/>
			</Helmet>

			<Routes>
				<Route
					path="/*"
					element={
						isAuthorized ? (
							<>
								<Helmet>
									<title>{title}</title>
								</Helmet>
								<div className={styles.root}>
									<div className={styles.content}>
										<Header
											title={title}
											showBookmarksIcon={true}
											showTopIcon={true}
											searchMode={
												currentTab.url === "/search"
											}
											onSearch={setSearchQuery}
											isSearching={isSearching}
											showLinkBack={false}
											nightModeEnabled={nightModeEnabled}
											nightModeToggle={nightModeToggle}
											isOverscrolled={isOverscrolled}
										/>
										<BottomNavigation
											onChange={onChangeTab}
											actions={tabsValues}
											activeActionId={currentTab.id}
											isOverscrolled={isOverscrolled}
										/>
										<SelectSubject
											tabId={currentTab.id}
											setSelectedSubject={
												setCurrentTabSelectedSubject
											}
											selectedSubject={
												currentTabSelectedSubject
											}
											isOverscrolled={isOverscrolled}
										/>

										{privatePages.map(
											({url, component: Component}) => (
												<Box
													key={url}
													display={
														location.pathname ===
														url
															? "block"
															: "none"
													}
												>
													{[
														"prepare",
														"session",
													].includes(
														currentTab.id
													) && (
														<TopProgressBar
															isOverscrolled={
																isOverscrolled
															}
														/>
													)}
													<Component
														sessionSelectedSubject={
															sessionSelectedSubject
														}
														prepareSelectedSubject={
															prepareSelectedSubject
														}
														setPrepareSelectedSubject={
															setPrepareSelectedSubject
														}
														openPrepareWithSubject={
															openPrepareWithSubject
														}
														openSessionWithSubject={
															openSessionWithSubject
														}
														isOverscrolled={
															isOverscrolled
														}
														searchQuery={
															searchQuery
														}
														setIsSearching={
															setIsSearching
														}
														isSearching={
															isSearching
														}
														url={url}
														active={
															location.pathname ===
															url
														}
													/>
												</Box>
											)
										)}

										<Routes>
											{privatePages.map(({url}) => (
												<Route
													key={url}
													path={url}
													element={null}
												/>
											))}

											<Route
												path="*"
												element={
													<Navigate to="/prepare" />
												}
											/>
										</Routes>
										{/*<Routes>
												{privatePages.map(
													({
														url,
														component: Component,
													}) => (
														<Route
															key={url}
															path={url}
															element={
																<Suspense
																	fallback={
																		<Loading />
																	}
																>
																	<Component
																		sessionSelectedSubject={
																			sessionSelectedSubject
																		}
																		prepareSelectedSubject={
																			prepareSelectedSubject
																		}
																		isOverscrolled={
																			isOverscrolled
																		}
																	/>
																</Suspense>
															}
														/>
													)
												)}

												<Route
													path="*"
													element={
														<Navigate to="/prepare" />
													}
												/>
											</Routes>*/}
									</div>
								</div>
							</>
						) : (
							<Navigate to="/about" />
						)
					}
				/>

				{publicPages.map(page => (
					<Route
						key={page.url}
						path={page.url}
						element={
							<div className={styles.root}>
								<div className={styles.content}>
									<Header
										title={title}
										showLinkBack={false}
										titleLinkHome
										nightModeEnabled={nightModeEnabled}
										nightModeToggle={nightModeToggle}
										isOverscrolled={isOverscrolled}
									/>

									<Suspense fallback={<Loading />}>
										<page.component />
									</Suspense>
								</div>
							</div>
						}
					/>
				))}
			</Routes>
		</UserContext.Provider>
	)
}

export default App
