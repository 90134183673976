import React from "react"
import {Link} from "react-router-dom"
import classnames from "classnames"

import styles from "./index.module.scss"

const Link_ = props => {
	const {
		block,
		external,
		internal,
		to,
		targetBlank = true,
		underline,
		children,
		className,
		...rest
	} = props

	const aProps = {
		//fixes warning
		target: targetBlank ? "_blank" : "_self",
		rel: targetBlank ? "noreferrer noopener" : "",
	}

	return internal ? (
		<Link
			to={to}
			data-underline={underline}
			data-block={block}
			className={classnames(styles.root, className)}
			{...rest}
		>
			{children}
		</Link>
	) : (
		<a
			href={to}
			data-underline={underline}
			data-block={block}
			className={classnames(styles.root, className)}
			{...aProps}
			{...rest}
		>
			{children}
		</a>
	)
}

export default Link_
