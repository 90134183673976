import React, {useState, useEffect, useRef} from "react"
import useApi from "api/useApi"
import {pluralize} from "js/utils"
import {useSnackbar} from "notistack"
import {reachGoal} from "js/ym"

import FadedAttention from "components/FadedAttention"
import PrepareQuestionCard from "components/QuestionCard/Prepare"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Subject from "components/Subject"
import Tab from "components/Tab"
import Tabs from "components/Tabs"
import TextSuggestion from "components/TextSuggestion"
import ErrorMessageBody from "components/ErrorMessageBody"

import SearchRoundedIcon from "@mui/icons-material/SearchRounded"
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded"

import styles from "./index.module.scss"

const minSearchQueryLength = 3
const limit = 7

const App = props => {
	const {searchQuery, setIsSearching, isSearching, openPrepareWithSubject} =
		props
	const prevSearchQuery = useRef(null)
	const blockScrollListener = useRef(false)

	const {searchQuestions, searchSubjects} = useApi()
	const {enqueueSnackbar} = useSnackbar()

	const [tab, setTab] = useState("questions")
	const prevTab = useRef(null)
	const [isLoadingMore, setIsLoadingMore] = useState(false)

	const [questions, setQuestions] = useState([])
	const questionsLength = useRef(questions.length)
	const questionsHasNext = useRef(true)
	const [questionsOffset, setQuestionsOffset] = useState(0)
	const questionsLastSearchQuery = useRef(null)

	const [subjects, setSubjects] = useState([])
	const subjectsLength = useRef(subjects.length)
	const subjectsHasNext = useRef(true)
	const [subjectsOffset, setSubjectsOffset] = useState(0)
	const subjectsLastSearchQuery = useRef(null)

	useEffect(() => {
		questionsLength.current = questions.length
	}, [questions])

	useEffect(() => {
		subjectsLength.current = subjects.length
	}, [subjects])

	useEffect(() => {
		;(async () => {
			if (searchQuery.length < minSearchQueryLength) {
				setSubjects([])
				questionsHasNext.current = true
			} else {
				try {
					blockScrollListener.current = true
					setIsLoadingMore(true)
					if (
						tab === "subjects" &&
						(prevTab.current === "subjects" ||
							subjectsLastSearchQuery.current !== searchQuery)
					) {
						subjectsLastSearchQuery.current = searchQuery
						setIsSearching(true)
						const {status, data} = await searchSubjects({
							q: searchQuery,
							limit,
							offset: subjectsOffset,
						})

						if (!status) {
							setSubjects([])
							setIsSearching(false)
							return
						}

						if (data.length === 0) {
							subjectsHasNext.current = false
						}
						setSubjects(prev => {
							const _data =
								subjectsOffset === 0
									? data
									: data.filter(
											d => !prev.some(p => p.id === d.id)
									  )
							return (subjectsOffset === 0 ? [] : prev).concat(
								_data.map(item => ({
									course: item.course,
									date: item.date,
									id: item.id,
									language: item.language,
									name: item.name,
									questions: item.questions,
									isNew: item.is_new,
									isSaved: item.is_saved,
									//usedCount: item.used_count,
								}))
							)
						})
						if (subjectsOffset === 0) {
							document.documentElement.scrollTop = 0
							reachGoal("subject_search")
						}
					}
					setIsLoadingMore(false)
					blockScrollListener.current = false
				} catch (err) {
					enqueueSnackbar({
						message: (
							<ErrorMessageBody
								message="Не удалось выполнить поиск. Проверьте подключение к сети."
								errors={{
									message: err.message,
									res: err.response,
									searchQuery,
								}}
							/>
						),
						variant: "error",
					})
					reachGoal("error")
				}
				setIsSearching(false)
			}
		})()
	}, [
		searchQuery,
		tab,
		enqueueSnackbar,
		searchSubjects,
		subjectsOffset,
		setIsSearching,
	])

	useEffect(() => {
		;(async () => {
			if (searchQuery.length < minSearchQueryLength) {
				setQuestions([])
				questionsHasNext.current = true
			} else {
				try {
					blockScrollListener.current = true
					setIsLoadingMore(true)
					if (
						tab === "questions" &&
						(prevTab.current === "questions" ||
							questionsLastSearchQuery.current !== searchQuery)
					) {
						questionsLastSearchQuery.current = searchQuery
						setIsSearching(true)
						const {status, data} = await searchQuestions({
							q: searchQuery,
							limit,
							offset: questionsOffset,
						})

						if (!status) {
							setQuestions([])
							setIsSearching(false)
							return
						}

						if (data.length === 0) {
							questionsHasNext.current = false
						}

						setQuestions(prev => {
							const _data =
								questionsOffset === 0
									? data
									: data.filter(
											d => !prev.some(p => p.id === d.id)
									  )
							return (questionsOffset === 0 ? [] : prev).concat(
								_data.map(item => ({
									id: item.id,
									note: (
										<>
											найдено в «
											<span
												onClick={() =>
													openPrepareWithSubject(
														item.subject
													)
												}
												className={styles.a}
											>
												{item.subject.name}
											</span>
											»
										</>
									),
									question: (
										<>
											{item.question.substring(
												0,
												item.str_pos[0]
											)}
											<span
												className={styles.highlighted}
											>
												{item.question.substring(
													item.str_pos[0],
													item.str_pos[1]
												)}
											</span>
											{item.question.substring(
												item.str_pos[1]
											)}
										</>
									),
									variants: [
										{
											variant: item.variant,
											isRight: true,
										},
									],
								}))
							)
						})
						if (questionsOffset === 0) {
							document.documentElement.scrollTop = 0
							reachGoal("question_search")
						}
					}
					setIsLoadingMore(false)
					blockScrollListener.current = false
				} catch (err) {
					enqueueSnackbar({
						message: (
							<ErrorMessageBody
								message="Не удалось выполнить поиск. Проверьте подключение к сети."
								errors={{
									message: err.message,
									res: err.response,
									searchQuery,
								}}
							/>
						),
						variant: "error",
					})
					reachGoal("error")
				}
				setIsSearching(false)
			}
		})()
	}, [
		searchQuery,
		tab,
		enqueueSnackbar,
		searchQuestions,
		questionsOffset,
		openPrepareWithSubject,
		setIsSearching,
	])

	useEffect(() => {
		prevSearchQuery.current = searchQuery
		questionsHasNext.current = true
		subjectsHasNext.current = true
		setSubjectsOffset(0)
		setQuestionsOffset(0)
	}, [searchQuery])

	useEffect(() => {
		prevTab.current = tab
	}, [tab])

	useEffect(() => {
		const onScroll = async () => {
			const toBottom =
				document.documentElement.scrollHeight -
				(document.documentElement.scrollTop + window.innerHeight)
			if (toBottom < 500 && !blockScrollListener.current) {
				if (tab === "subjects") {
					subjectsHasNext.current &&
						setSubjectsOffset(prev => prev + limit)
				} else if (tab === "questions") {
					questionsHasNext.current &&
						setQuestionsOffset(prev => prev + limit)
				}
				blockScrollListener.current = true
			}
		}
		window.addEventListener("scroll", onScroll)
		;(async () => {
			await onScroll(true)
		})()

		return () => window.removeEventListener("scroll", onScroll)
	}, [tab])

	const enoughSearchQueryLength = searchQuery.length >= minSearchQueryLength

	return (
		<>
			{!enoughSearchQueryLength && (
				<>
					<TextSuggestion icon={LightbulbRoundedIcon} color="purple">
						Поиск ищет совпадения среди вопросов и предметов по всей
						базе за все года.
						<br />
						<br />
						Чтобы познакомиться с поиском, введите «<i>маркетинг</i>
						» или «<i>в каком году</i>».
					</TextSuggestion>
					{searchQuery.length === 0 && (
						<FadedAttention
							icon={SearchRoundedIcon}
							message={`Чтобы начать поиск, введите как минимум ${minSearchQueryLength} ${pluralize(
								minSearchQueryLength,
								"символ",
								"символа",
								"символов"
							)}`}
						/>
					)}
					{searchQuery.length > 0 && (
						<FadedAttention
							icon={SearchRoundedIcon}
							message={`Чтобы начать поиск, введите еще ${
								minSearchQueryLength - searchQuery.length
							} ${pluralize(
								minSearchQueryLength - searchQuery.length,
								"символ",
								"символа",
								"символов"
							)}`}
						/>
					)}
				</>
			)}

			{enoughSearchQueryLength && (
				<div className={styles.container}>
					<Tabs
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						scrollButtons={false}
						variant="fullWidth"
						className={styles.tabs}
					>
						<Tab label="Вопросы" value="questions" />
						<Tab label="Предметы" value="subjects" />
					</Tabs>

					{tab === "subjects" &&
						(subjects.length > 0
							? subjects.map(item => (
									<Box key={`s${item.id}`} mb={2}>
										<Subject
											fullWidth
											onClick={() =>
												openPrepareWithSubject(item)
											}
											{...item}
										/>
									</Box>
							  ))
							: !isSearching && (
									<FadedAttention
										icon={SearchRoundedIcon}
										message="Ничего не найдено"
									/>
							  ))}

					{tab === "questions" &&
						(questions.length > 0
							? questions.map((item, index) => (
									<Box key={`q${item.id}`} mb={2}>
										<PrepareQuestionCard
											index={index + 1}
											isAnswersShown={true}
											hideAction={true}
											{...item}
										/>
									</Box>
							  ))
							: !isSearching && (
									<FadedAttention
										icon={SearchRoundedIcon}
										message="Ничего не найдено"
									/>
							  ))}

					{!isSearching && isLoadingMore && (
						<div className={styles.loadingMore}>
							<CircularProgress
								size={24}
								thickness={4}
								className={styles.progress}
							/>
						</div>
					)}
				</div>
			)}

			{/*<BottomActions isOverscrolled={isOverscrolled}>
						{isSessionPassed ? (
							<Button
								variant="primary"
								iconAfter={ReplayRoundedIcon}
								onClick={loadSubject}
							>
								Пройти еще раз
							</Button>
						) : (
							<Button
								variant={
									Object.keys(selectedVariants).length ===
									questionsAmount
										? "positive"
										: "primary"
								}
								iconAfter={CheckCircleRoundedIcon}
								onClick={onPassSession}
							>
								Сдать сессию
							</Button>
						)}
					</BottomActions>
					<FadedAttention
						icon={ArrowDownwardRoundedIcon}
						message="Ответьте на все вопросы и сдайте сессию"
					/>*/}
		</>
	)
}

export default App
