import {createSlice} from "@reduxjs/toolkit"

export const universitiesSlice = createSlice({
	name: "universities",
	initialState: [
		{
			label: "Все учебные заведения",
			value: 0,
		},
	],
	reducers: {
		setUniversities: (state, action) => {
			return action.payload
		},
	},
})

export const {setUniversities} = universitiesSlice.actions

export default universitiesSlice.reducer
