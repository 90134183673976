import React, {useState, useEffect, useRef} from "react"
import useApi from "api/useApi"
import {useSnackbar} from "notistack"
import {reachGoal} from "js/ym"
//import useDialog from "hooks/useDialog"

import FadedAttention from "components/FadedAttention"
import Box from "@mui/material/Box"
import Subject from "components/Subject"
import Loading from "components/Loading"
import ErrorMessageBody from "components/ErrorMessageBody"

import BackupRoundedIcon from "@mui/icons-material/BackupRounded"

//import styles from "./index.module.scss"

const App = props => {
	const {active} = props

	/*const {
		open: openRemoveDialog,
		close: closeRemoveDialog,
		props: removeDialogProps,
		Component: RemoveDialog,
	} = useDialog()*/

	const {getRequests} = useApi()
	const {enqueueSnackbar} = useSnackbar()

	const [subjects, setSubjects] = useState([])
	const subjectsLength = useRef(subjects.length)
	const [isLoading, setIsLoading] = useState(true)

	/*const onRemove = useCallback(async () => {
		setIsRemoving(true)
		const {status} = await clearBookmarks()
		if (status) {
			enqueueSnackbar({
				message: "Все ваши закладки были удалены.",
				variant: "success",
			})
			setSubjects([])
			closeRemoveDialog()
		} else {
			enqueueSnackbar({
				message: "Ошибка. Не удалось очистить закладки.",
				variant: "error",
			})
		}
		setIsRemoving(false)
	}, [clearBookmarks, enqueueSnackbar, closeRemoveDialog])*/

	useEffect(() => {
		subjectsLength.current = subjects.length
	}, [subjects])

	useEffect(() => {
		if (!active) return
		;(async () => {
			subjectsLength.current === 0 && setIsLoading(true)
			try {
				const {status, data} = await getRequests()
				if (status) {
					setSubjects(
						data.map(item => ({
							course: item.course,
							date: item.date,
							name: `Тест: ${item.subject_name}`,
							id: item.id,
							language: item.language,
						}))
					)
				} else {
					enqueueSnackbar({
						message: "Не удалось загрузить заявки.",
						variant: "error",
					})
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось загрузить заявки. Проверьте подключение к сети."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
			setIsLoading(false)
		})()
	}, [getRequests, enqueueSnackbar, active])

	return (
		<>
			{/*<RemoveDialog
				{...removeDialogProps}
				title="Очистить все закладки?"
				action={
					<>
						<Button
							variant="negative"
							wide
							onClick={onRemove}
							isLoading={isRemoving}
							iconAfter={DeleteForeverRoundedIcon}
						>
							Да, очистить
						</Button>
						<Button
							variant="primary"
							wide
							onClick={closeRemoveDialog}
						>
							Отменить
						</Button>
					</>
				}
			>
				Все ваши текущие закладки будут удалены.
			</RemoveDialog>*/}

			{isLoading && <Loading />}

			{!isLoading && subjects.length === 0 && (
				<FadedAttention
					icon={BackupRoundedIcon}
					message="Сюда будут попадать отправленные вами предметы"
				/>
			)}

			{!isLoading && subjects.length > 0 && (
				<>
					{subjects.map(item => (
						<Box key={item.id} mb={2}>
							<Subject
								fullWidth
								showActions={false}
								questions="На рассмотрении ⌛"
								{...item}
							/>
						</Box>
					))}
				</>
			)}
		</>
	)
}

export default App
