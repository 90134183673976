import React, {Fragment, useContext, useState, useEffect} from "react"
import {numberWithSpaces} from "js/utils"
import UserContext from "contexts/user"
import useApi from "api/useApi"
import {useSnackbar} from "notistack"
import {reachGoal} from "js/ym"

import Box from "@mui/material/Box"
import Card from "components/Card"
import Loading from "components/Loading"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import TextSuggestion from "components/TextSuggestion"
import FadedAttention from "components/FadedAttention"
import ErrorMessageBody from "components/ErrorMessageBody"

import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded"
//import StarRoundedIcon from "@mui/icons-material/StarRounded"

import cabinetStyles from "../../cabinet/App/index.module.scss"
import currentStyles from "./index.module.scss"
const styles = {
	...cabinetStyles,
	...currentStyles,
}

const App = props => {
	const {active} = props
	const {user} = useContext(UserContext)
	const {getGlobalSessionRating} = useApi()
	const {enqueueSnackbar} = useSnackbar()

	const [top, setTop] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const showRating = top.length >= 3

	useEffect(() => {
		if (!active) return
		;(async () => {
			setIsLoading(true)
			try {
				const {status, data} = await getGlobalSessionRating()
				if (status) {
					setTop(data)
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось загрузить список."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
			setIsLoading(false)
		})()
	}, [getGlobalSessionRating, enqueueSnackbar, active])

	return (
		<>
			{isLoading && <Loading />}

			{!isLoading && !showRating && (
				<FadedAttention
					icon={EmojiEventsRoundedIcon}
					message="Рейтинг еще не составлен. Зайдите немного позже."
				/>
			)}

			{showRating && (
				<>
					<Box mb={2}>
						<TextSuggestion
							icon={EmojiEventsRoundedIcon}
							color="yellow"
						>
							Это список лучших пользователей Тестника за
							последние 7 дней. Они набрали больше всего баллов за
							сессию суммарно.
							<br />
							<br />
							Чтобы попасть в топ нужно проходить сессию в
							Тестнике как можно точнее, делая минимум ошибок.
						</TextSuggestion>
					</Box>

					<Card>
						<List className={styles.list}>
							{top.map((item, index) => (
								<Fragment key={item.id}>
									{index > 0 && (
										<Divider
											className={styles.listDivider}
										/>
									)}
									<ListItem
										key={item.id}
										className={styles.listItem}
										data-me={user.id === item.id}
									>
										<ListItemAvatar
											className={styles.listItemAvatar}
										>
											<Avatar
												sx={{width: 28, height: 28}}
												className={styles.avatar}
												data-primary={index <= 2}
											>
												{["🥇", "🥈", "🥉"][index] ||
													index + 1}
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primaryTypographyProps={{
												className:
													styles.listItemPrimaryText,
											}}
											primary={
												<div className={styles.user}>
													<div
														className={styles.name}
													>
														{item.email}
													</div>
													<div
														className={styles.total}
													>
														{numberWithSpaces(
															item.total
														)}
													</div>
												</div>
											}
										/>
									</ListItem>
								</Fragment>
							))}
						</List>
					</Card>
				</>
			)}
		</>
	)
}

export default App
