import {createSlice} from "@reduxjs/toolkit"

export const authSlice = createSlice({
	name: "isAuthorized",
	initialState: true,
	reducers: {
		auth: (state, action) => {
			return true
		},
		unauth: (state, action) => {
			return false
		},
	},
})

export const {auth, unauth} = authSlice.actions

export default authSlice.reducer
