import React from "react"
import {wame} from "js/utils"

import Link from "components/Link"

const ErrorMessageBody = props => {
	const {message, errors} = props

	const errorCode = JSON.stringify({
		section: window.location.pathname,
		...errors,
	})

	return (
		<>
			{message}
			<br />
			<br />
			Сделайте скриншот кода ниже и отправьте его в{" "}
			<Link
				external
				to={wame({
					message: `Привет. У меня возникли проблемы с Тестником. Код ошибки:\n\n${errorCode}`,
				})}
				underline="always"
			>
				чат поддержки
			</Link>
			:
			<br />
			<br />
			<tt style={{lineBreak: "anywhere", fontSize: "12px", lineHeight: "1em"}}>{errorCode}</tt>
		</>
	)
}

export default ErrorMessageBody
