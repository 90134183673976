import React, {
	useState,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from "react"
import useApi from "api/useApi"
import {languageFlags} from "js/utils"
import {useSnackbar} from "notistack"
import UserContext from "contexts/user"
import {reachGoal} from "js/ym"
import {useSelector} from "react-redux"

import Card from "components/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import CardTitle from "components/CardTitle"
import Box from "@mui/material/Box"
import TextField from "components/TextField"
import Button from "components/Button"
import FileUploadField from "components/FileUploadField"
import OpenSelect from "components/OpenSelect"
import ErrorMessageBody from "components/ErrorMessageBody"
import FormHelperText from "@mui/material/FormHelperText"

import ShortTextRoundedIcon from "@mui/icons-material/ShortTextRounded"
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded"
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"

import styles from "./index.module.scss"
import MenuItem from "@mui/material/MenuItem"
import {Select} from "../../../components/SubjectFilters"

const App = () => {
	const {uploadSubject} = useApi()
	const {enqueueSnackbar} = useSnackbar()
	const {user} = useContext(UserContext)
	const [isLoading, setIsLoading] = useState(false)

	const [name, setName] = useState("")
	const [language, setLanguage] = useState("")
	const [course, setCourse] = useState("")
	const [university, setUniversity] = useState("")
	const [file, setFile] = useState(null)

	const _universities = useSelector(state => state.universities)
	const universities = useMemo(() => {
		return [
			{
				label: "Нет нужного в списке",
				value: 0,
			},
			..._universities.slice(1),
		]
	}, [_universities])

	const onSubmit = useCallback(
		async e => {
			e.preventDefault()
			const nameTrim = name.trim().substr(0, 256)

			if (nameTrim.length < 3) {
				enqueueSnackbar({
					message:
						"Слишком короткое имя предмета. Напишите полное имя без сокращений.",
					variant: "error",
				})
				return
			}
			if (!file) {
				enqueueSnackbar({
					message: "Вы забыли прикрепить файл.",
					variant: "warning",
				})
				return
			}
			if (typeof university !== "number") {
				enqueueSnackbar({
					message: "Укажите учебное заведение.",
					variant: "warning",
				})
				return
			}
			if (!course) {
				enqueueSnackbar({
					message: "Вы забыли выбрать курс.",
					variant: "warning",
				})
				return
			}
			if (!language) {
				enqueueSnackbar({
					message: "Вы заыбли выбрать язык обучения.",
					variant: "warning",
				})
				return
			}

			setIsLoading(true)
			try {
				const {status, data} = await uploadSubject({
					subject_name: nameTrim,
					course,
					language,
					university_id: university,
					file,
				})

				if (status) {
					enqueueSnackbar({
						message:
							"Заявка отправлена и будет рассмотренна модератором в течение дня. Как только тест будет одобрен, мы оповестим вас в WhatsApp.",
						variant: "success",
					})
					setName("")
					setFile(null)
					reachGoal("subject_uploaded")
				} else {
					switch (data) {
						case "too_short_subject_name": {
							enqueueSnackbar({
								message: "Слишком короткое название предмета.",
								variant: "error",
							})
							break
						}
						case "file_extension_error": {
							enqueueSnackbar({
								message:
									"Недопустимое расширение файла. Вы можете загружать файлы формата .doc, .docx, .txt или .pdf.",
								variant: "error",
							})
							break
						}
						case "too_big_file": {
							enqueueSnackbar({
								message:
									"Превышен максимальный размер файла (4 Мб)",
								variant: "error",
							})
							break
						}
						case "file_exists": {
							enqueueSnackbar({
								message:
									"Вы уже загрузили этот тест. Загружать повторно не нужно. Мы обязательно рассмотрим вашу заявку.",
								variant: "error",
							})
							setName("")
							setFile(null)
							break
						}
						default: {
							enqueueSnackbar({
								message: (
									<ErrorMessageBody
										message="Не удалось отправить предмет."
										errors={{
											data,
											status,
											nameTrim,
											course,
											language,
											university,
										}}
									/>
								),
								variant: "error",
							})
							reachGoal("error")
						}
					}
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось загрузить предмет. Проверьте подключение к сети."
							errors={{
								message: err.message,
								res: err.response,
								nameTrim,
								course,
								language,
								university,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
			setIsLoading(false)
		},
		[
			name,
			file,
			university,
			course,
			language,
			enqueueSnackbar,
			uploadSubject,
		]
	)

	useEffect(() => {
		setLanguage(user.language)
		setCourse(user.course)
		user.university_id !== 0 && setUniversity(user.university_id)
	}, [user])

	return (
		<Card>
			<form onSubmit={onSubmit}>
				<CardContent>
					<CardTitle variant="h6" mb={0}>
						Отправить тест
					</CardTitle>
					<div className={styles.warnBlock}>
						<Typography variant="subtitle1" component="p">
							<WarningRoundedIcon className={styles.warnIcon} />️
							Перед загрузкой теста попробуйте сначала найти его в
							поиске по первому вопросу
						</Typography>
					</div>
					<Box mb={2}>
						<TextField
							label={
								<>
									<u>Полное</u> название предмета
								</>
							}
							placeholder="Название без сокращений"
							value={name}
							onChange={e => setName(e.target.value)}
							type="text"
							name="name"
							icon={ShortTextRoundedIcon}
							required
						/>
						{/202\d/.test(name) && (
							<FormHelperText error>
								Год указывать не нужно
							</FormHelperText>
						)}
						{/[A-ZА-Я]{3,}/.test(name) && (
							<FormHelperText error>
								Расшифруйте аббревиатуру
							</FormHelperText>
						)}
						{/Каз[А-Я]+|[Уу]ниверситет/.test(name) && (
							<FormHelperText error>
								Университет указывать не нужно
							</FormHelperText>
						)}
						{/\d курс/i.test(name) && (
							<FormHelperText error>
								Курс указывать не нужно
							</FormHelperText>
						)}
						{/математика|физика/.test(name) && (
							<FormHelperText error>
								Тесты с формулами и картинками сейчас не
								поддерживаются
							</FormHelperText>
						)}
					</Box>
					<Box mb={2}>
						<FileUploadField
							name="file"
							accept=".doc,.docx,.rtf"
							placeholder="Прикрепите файл, где первые варианты ответов — правильные"
							value={file}
							onChange={setFile}
						/>
					</Box>

					<Box mb={2}>
						<Select
							value={university}
							onChange={e => setUniversity(e.target.value)}
							label="Учебное заведение"
						>
							{universities.map(option => (
								<MenuItem
									key={option.value}
									value={option.value}
									className={styles.menuItem}
									divider
								>
									{option.label}
								</MenuItem>
							))}
						</Select>
						{university === 0 && (
							<FormHelperText error>
								Вы уверены, что вашего университета нет в
								списке?
							</FormHelperText>
						)}
					</Box>

					<Box mb={2}>
						<OpenSelect
							placeholder="Курс"
							value={course}
							onChange={setCourse}
							icon={AccountBalanceRoundedIcon}
							name="course"
							options={[
								{
									value: 1,
									children: 1,
									basis: 4,
								},
								{
									value: 2,
									children: 2,
									basis: 4,
								},
								{
									value: 3,
									children: 3,
									basis: 4,
								},
								{
									value: 4,
									children: 4,
									basis: 4,
								},
							]}
						/>
					</Box>
					<Box mb={2}>
						<OpenSelect
							placeholder="Отделение (язык обучения)"
							value={language}
							onChange={setLanguage}
							icon={TranslateRoundedIcon}
							name="language"
							options={[
								{
									value: "kz",
									children: (
										<img
											alt="kzz"
											src={languageFlags.kz}
											className={styles.languageIcon}
										/>
									),
									basis: 3,
								},
								{
									value: "ru",
									children: (
										<img
											alt="ru"
											src={languageFlags.ru}
											className={styles.languageIcon}
										/>
									),
									basis: 3,
								},
								{
									value: "en",
									children: (
										<img
											alt="en"
											src={languageFlags.en}
											className={styles.languageIcon}
										/>
									),
									basis: 3,
								},
							]}
						/>
					</Box>
				</CardContent>
				<Button
					variant="primary"
					isLoading={isLoading}
					type="submit"
					wide
				>
					Отправить
				</Button>
			</form>
		</Card>
	)
}

export default App
