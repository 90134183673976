import React, {useState, useCallback, useMemo} from "react"
import useApi from "api/useApi"
import {wame, pluralize} from "js/utils"
import classnames from "classnames"
import {useSnackbar} from "notistack"
import {reachGoal} from "js/ym"

import Card from "components/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Link from "components/Link"

import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined"
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded"
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded"
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined"
import ReportRoundedIcon from "@mui/icons-material/ReportRounded"

import commonStyles from "../common.module.scss"
import styles from "./index.module.scss"

const PrepareQuestionCard = props => {
	const {
		id,
		is_saved: isSavedProp,
		note,
		variants,
		hideAction,
		question,
		index,
		isAnswersShown,
		className,
		classes = {},
	} = props

	const {enqueueSnackbar} = useSnackbar()
	const {saveToBookmarks} = useApi()

	const [selected, setSelected] = useState([])
	const [showRight, setShowRight] = useState(null)
	const [faded, setFaded] = useState([])
	const [isSaved, setIsSaved] = useState(isSavedProp)
	const rightAnswersCount = useMemo(() => {
		let _rightAnswersCount = 0
		for (let i = 0; i < variants.length; i++) {
			if (variants[i].is_right) {
				_rightAnswersCount++
			}
		}
		return _rightAnswersCount
	}, [variants])
	const isMultipleAnswers = rightAnswersCount > 1

	const getState = useCallback(
		({isRight, index}) => {
			if (variants.length === 1) {
				return "right"
			} else if (faded.includes(index)) {
				return "faded"
			} else if (selected.length > 0 && !isAnswersShown) {
				const isCurrentVariantSelected = selected.includes(index)
				if (isRight && isCurrentVariantSelected) {
					return "right"
				} else if (!isRight && isCurrentVariantSelected) {
					return "wrong"
				} else if (
					!isRight &&
					!isCurrentVariantSelected &&
					(selected.some(
						selectedVariantIndex =>
							!variants[selectedVariantIndex].is_right
					) ||
						selected.length === rightAnswersCount)
				) {
					return "faded"
				} else if (isRight && !isCurrentVariantSelected && showRight) {
					return "right"
				}
			} else if (isAnswersShown) {
				if (isRight) {
					return "right"
				} else {
					return "faded"
				}
			} else {
				return ""
			}
		},
		[
			variants,
			faded,
			selected,
			isAnswersShown,
			rightAnswersCount,
			showRight,
		]
	)

	const onSelect = useCallback(
		index => {
			if (
				!isAnswersShown &&
				selected.length < rightAnswersCount &&
				variants.length > 1 &&
				!faded.includes(index) &&
				!showRight
			) {
				const newSelected = selected.concat(index)
				setSelected(newSelected)
				if (!variants[index].is_right) {
					setTimeout(() => {
						setShowRight(true)
					}, 300)
				}
			}
		},
		[
			isAnswersShown,
			selected,
			rightAnswersCount,
			variants,
			faded,
			showRight,
		]
	)

	const fadeWrong = useCallback(() => {
		if (faded.length === 0 && !isAnswersShown) {
			reachGoal("tip")
			const _faded = []
			do {
				const randomIndex = Math.round(
					Math.random() * (variants.length - 1)
				)
				const randomItem = variants[randomIndex]
				if (!randomItem.is_right && !_faded.includes(randomIndex)) {
					_faded.push(randomIndex)
					variants.filter((item, index) => index !== randomIndex)
				}
			} while (_faded.length < variants.length - rightAnswersCount - 1)
			setFaded(_faded)
		}
	}, [faded, isAnswersShown, variants, rightAnswersCount])

	const onSaveToBookmarks = useCallback(async () => {
		setIsSaved(prev => !prev)
		const {status, data} = await saveToBookmarks({question_id: id})
		if (status) {
			if (typeof data.saved === "boolean") {
				setIsSaved(data.saved)
				data.saved && reachGoal("question_save")
			}
		} /* else {
			reachGoal("error")
		}*/
	}, [id, saveToBookmarks])

	const onReport = useCallback(() => {
		enqueueSnackbar({
			message: (
				<>
					Если в вопросе есть ошибка или вопрос отображается
					некорректно, вы можете запросить исправление.
					<br />
					<br />
					<Link
						to={wame({
							message: `Привет. Хочу запросить исправление вопроса в Тестнике.\n\nВопрос: «${question}».\nИдентификатор вопроса: ${id}.`,
						})}
						external
					>
						Нажмите здесь, чтобы запросить исправление этого
						вопроса.
					</Link>
				</>
			),
			variant: "warning",
		})
	}, [question, id, enqueueSnackbar])

	return (
		<Card
			className={classnames(commonStyles.root, className, classes.root)}
		>
			<CardHeader
				title={
					<>
						<span className={commonStyles.title}>
							{typeof index === "number" && (
								<div className={commonStyles.index}>
									{index}
								</div>
							)}
							{question}
						</span>
						{isMultipleAnswers && (
							<>
								<br />
								<Typography
									variant="subtitle2"
									className={commonStyles.multipleAnswers}
								>
									{faded.length === 0
										? "Несколько правильных ответов:"
										: `${rightAnswersCount} ${pluralize(
												rightAnswersCount,
												"правильный",
												"правильных",
												"правильных"
										  )} ${pluralize(
												rightAnswersCount,
												"ответ",
												"ответа",
												"ответов"
										  )}:`}
								</Typography>
							</>
						)}
					</>
				}
			/>
			<CardContent className={commonStyles.content}>
				{variants.map((item, index) => (
					<div
						className={classnames(
							commonStyles.variant,
							styles.variant
						)}
						key={index}
						data-state={getState({isRight: item.is_right, index})}
						onClick={() => onSelect(index)}
					>
						{item.variant}
					</div>
				))}
				{note && (
					<Typography
						variant="subtitle1"
						className={commonStyles.note}
					>
						{note}
					</Typography>
				)}
			</CardContent>

			{!hideAction && (
				<CardActions disableSpacing className={commonStyles.actions}>
					<IconButton
						className={classnames(
							commonStyles.button,
							commonStyles.reportButton
						)}
						onClick={onReport}
					>
						<ReportRoundedIcon className={commonStyles.icon} />
					</IconButton>
					<IconButton
						className={commonStyles.button}
						onClick={fadeWrong}
						disabled={
							(selected.length > 0 && faded.length === 0) ||
							isAnswersShown ||
							variants.length < 3
						}
					>
						{variants.length >= 3 && faded.length > 0 ? (
							<TipsAndUpdatesRoundedIcon
								className={commonStyles.icon}
							/>
						) : (
							<TipsAndUpdatesOutlinedIcon
								className={commonStyles.icon}
							/>
						)}
					</IconButton>
					<IconButton
						className={commonStyles.button}
						onClick={onSaveToBookmarks}
					>
						{isSaved ? (
							<BookmarkRoundedIcon
								className={commonStyles.icon}
							/>
						) : (
							<BookmarkBorderOutlinedIcon
								className={commonStyles.icon}
							/>
						)}
					</IconButton>
				</CardActions>
			)}
		</Card>
	)
}

export default PrepareQuestionCard
