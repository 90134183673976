import React, {
	Fragment,
	useContext,
	useCallback,
	useState,
	useEffect,
	useRef,
} from "react"
import {useNavigate} from "react-router-dom"
import {wame, pluralize, numberWithSpaces} from "js/utils"
import UserContext from "contexts/user"
import {useDispatch} from "react-redux"
import {unauth} from "../../../redux/auth-slice"
import useApi from "api/useApi"
import useDialog from "hooks/useDialog"
import classnames from "classnames"
import {useSnackbar} from "notistack"
import {reachGoal} from "js/ym"
import {
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	CartesianGrid,
	Area,
	AreaChart,
} from "recharts"

import format from "date-fns/format"
import parse from "date-fns/parse"
import differenceInDays from "date-fns/differenceInDays"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import sub from "date-fns/sub"
import {ru} from "date-fns/locale"

import Box from "@mui/material/Box"
import Card from "components/Card"
import Button from "components/Button"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemIcon from "@mui/material/ListItemIcon"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import Link from "components/Link"
import TextField from "components/TextField"
import ErrorMessageBody from "components/ErrorMessageBody"

import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded"
import BackupRoundedIcon from "@mui/icons-material/BackupRounded"
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded"
import LockRoundedIcon from "@mui/icons-material/LockRounded"
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded"
import LiveHelpRoundedIcon from "@mui/icons-material/LiveHelpRounded"
import SecurityRoundedIcon from "@mui/icons-material/SecurityRounded"
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import ChangeCircleRoundedIcon from "@mui/icons-material/ChangeCircleRounded"
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded"
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded"
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded"
import DnsRoundedIcon from "@mui/icons-material/DnsRounded"
//import StarRoundedIcon from "@mui/icons-material/StarRounded"

import styles from "./index.module.scss"

const mainColor = "#5e0fec"
const thisWeek = eachDayOfInterval({
	start: sub(new Date(), {days: 6}),
	end: new Date(),
}).map(item => format(item, "yyyy-MM-dd"))

const onGraphMouseEnter = (() => {
	let executed = false
	return () => {
		if (!executed) {
			reachGoal("statistics_explore")
			executed = true
		}
	}
})()

const App = props => {
	const {active} = props
	const {user} = useContext(UserContext)
	const {
		getUserScoresGraph,
		changePassword,
		setToken,
		resetToken,
		getProxies,
	} = useApi()
	const graphWrapperEl = useRef()
	const navigate = useNavigate()
	const {enqueueSnackbar} = useSnackbar()
	const dispatch = useDispatch()

	const [isChangingPassword, setIsChangingPassword] = useState(false)
	const [oldPassword, setOldPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordRepeat, setNewPasswordRepeat] = useState("")

	const [rechartsKey, setRechartsKey] = useState(0)
	const [sessionGraph, setSessionGraph] = useState(
		thisWeek.map(date => ({date, total: 0}))
	)
	const hasAnyGraphData = sessionGraph.some(item => item.total > 0)
	const [weekRating, setWeekRating] = useState(0)
	const [sessionsPassed, setSessionsPassed] = useState(0)
	const weekAverageRating =
		sessionsPassed > 0 ? Math.round(weekRating / sessionsPassed) : 0
	const [graphWidth, setGraphWidth] = useState(468)

	const [proxies, setProxies] = useState([])

	const {
		open: openChangePasswordDialog,
		close: closeChangePasswordDialog,
		props: changePasswordDialogProps,
		Component: ChangePasswordDialog,
	} = useDialog()

	const {
		open: openRestoresDialog,
		close: closeRestoresDialog,
		props: restoresDialogProps,
		Component: RestoresDialog,
	} = useDialog()

	const onLogout = useCallback(() => {
		resetToken()
		dispatch(unauth())
		navigate("/about")
	}, [resetToken, navigate, dispatch])

	const list = [
		{
			type: "internal-link",
			title: "Мои закладки",
			subtitle: "Карточки с вопросами, которые вы сохранили на потом",
			url: "/bookmarks",
			icon: BookmarkRoundedIcon,
		},
		{
			type: "internal-link",
			title: "Мои заявки",
			subtitle: "Предметы, которые вы отправили на добавление",
			url: "/requests",
			icon: BackupRoundedIcon,
		},
		{
			type: "internal-link",
			title: "Рейтинг лучших",
			subtitle:
				"Студенты, набравшие больше всего баллов за сессию суммарно в течение недели",
			url: "/top",
			icon: EmojiEventsRoundedIcon,
		},
		{
			type: "external-link",
			title: "Написать в поддержку",
			subtitle: "На случай, если у вас возникнут вопросы или сбои",
			url: wame({
				message:
					"Привет. У меня возникли некоторые вопросы по поводу Тестника.",
			}),
			icon: SupportAgentRoundedIcon,
		},
		{
			type: "button",
			title: "Сменить пароль",
			url: "/change-password",
			icon: LockRoundedIcon,
			onClick: openChangePasswordDialog,
		},
		{
			type: "internal-link",
			title: "Часто задаваемые вопросы",
			url: "/faq",
			icon: LiveHelpRoundedIcon,
		},
		{
			type: "internal-link",
			title: "Пользовательское соглашение",
			url: "/terms",
			icon: SecurityRoundedIcon,
		},
		{
			type: "external-link",
			title: "Все сервисы Тестника",
			url: "https://services.testnik.kz",
			icon: ViewInArRoundedIcon,
		},
		{
			type: "button",
			title: "Выйти из аккаунта",
			icon: LogoutRoundedIcon,
			onClick: onLogout,
		},
	]

	const onChangePassword = useCallback(
		async e => {
			e.preventDefault()

			if (
				[newPassword, newPasswordRepeat, oldPassword].some(
					item => !item
				)
			) {
				enqueueSnackbar({
					message: "Заполните все поля.",
					variant: "error",
				})
				return
			}
			if (newPassword !== newPasswordRepeat) {
				enqueueSnackbar({
					message: "Пароли не совпадают.",
					variant: "error",
				})
				return
			}
			if (newPassword.length < 6) {
				enqueueSnackbar({
					message: "Слишком короткий пароль.",
					variant: "error",
				})
				return
			}
			setIsChangingPassword(false)
			try {
				reachGoal("password_change")
				const {status, data} = await changePassword({
					oldpassword: oldPassword,
					newpassword1: newPassword,
					newpassword2: newPasswordRepeat,
				})

				if (status) {
					enqueueSnackbar({
						message: "Пароль успешно изменен. Перезагрузка...",
						variant: "success",
					})
					setToken({token: data.token})
					setNewPassword("")
					setNewPasswordRepeat("")
					setOldPassword("")
					closeChangePasswordDialog()
					document.documentElement.scrollTop = 0
					setTimeout(() => {
						window.location.reload()
					}, 2000)
				} else {
					switch (data) {
						case "wrong_password_or_email": {
							enqueueSnackbar({
								message: "Неверный пароль.",
								variant: "error",
							})
							break
						}
						default: {
							enqueueSnackbar({
								message: (
									<ErrorMessageBody
										message="Не удалось сменить пароль."
										errors={{
											data,
											status,
										}}
									/>
								),
								variant: "error",
							})
							reachGoal("error")
						}
					}
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось сменить пароль. Проверьте подключение к сети."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
			setIsChangingPassword(false)
		},
		[
			oldPassword,
			newPassword,
			newPasswordRepeat,
			enqueueSnackbar,
			closeChangePasswordDialog,
			changePassword,
			setToken,
		]
	)

	useEffect(() => {
		if (!active) return
		;(async () => {
			try {
				const {status, data} = await getUserScoresGraph()
				if (status) {
					const {total, amount, graph} = data

					const graph_ = thisWeek.map(dateString => {
						const foundDate = graph.find(
							item => item.date === dateString
						)
						return {
							date: dateString,
							total: foundDate ? foundDate.total : 0,
						}
					})
					setSessionGraph(graph_)
					setWeekRating(total)
					setSessionsPassed(amount)
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось загрузить график результатов сессии."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
		})()
	}, [getUserScoresGraph, enqueueSnackbar, active])

	useEffect(() => {
		if (!active) return
		;(async () => {
			try {
				const {status, data} = await getProxies()
				if (!status) return
				setProxies(data)
			} catch (err) {
				console.error(err)
			}
		})()
	}, [active, getProxies])

	useEffect(() => {
		if (!active) return
		const onResize = () => {
			if (!graphWrapperEl.current) return
			const el = graphWrapperEl.current
			const computedStyle = getComputedStyle(el)
			const paddingX =
				parseFloat(computedStyle.paddingLeft) +
				parseFloat(computedStyle.paddingRight)
			setGraphWidth(el.offsetWidth - paddingX)
		}
		setRechartsKey(prev => prev + 1)
		setTimeout(() => requestAnimationFrame(onResize), 100)
		window.addEventListener("resize", onResize)

		return () => window.removeEventListener("resize", onResize)
	}, [active])

	const subscriptionDatesLeft =
		differenceInDays(user.expires_at, new Date()) + 1

	return (
		<>
			<ChangePasswordDialog
				{...changePasswordDialogProps}
				title="Смена пароля"
				action={
					<>
						<Button
							variant="primary"
							wide
							onClick={closeChangePasswordDialog}
						>
							Отменить
						</Button>
						<Button
							variant="positive"
							wide
							onClick={onChangePassword}
							isLoading={isChangingPassword}
							iconAfter={ChangeCircleRoundedIcon}
						>
							Сменить
						</Button>
					</>
				}
			>
				<form onSubmit={e => e.preventDefault}>
					<Box mb={2}>
						<TextField
							label="Старый пароль"
							value={oldPassword}
							onChange={e => setOldPassword(e.target.value)}
							type="password"
							autoComplete="current-password"
							icon={HttpsRoundedIcon}
							required
						/>
					</Box>
					<Box mb={2}>
						<TextField
							label="Новый пароль"
							value={newPassword}
							onChange={e => setNewPassword(e.target.value)}
							type="password"
							autoComplete="new-password"
							icon={HttpsRoundedIcon}
							required
						/>
					</Box>
					<TextField
						label="Повторите новый пароль"
						value={newPasswordRepeat}
						onChange={e => setNewPasswordRepeat(e.target.value)}
						type="password"
						autoComplete="new-password"
						icon={HttpsRoundedIcon}
						required
					/>
				</form>
			</ChangePasswordDialog>

			<RestoresDialog
				{...restoresDialogProps}
				title="О лимите смены устройства"
				action={
					<>
						<Button
							variant="primary"
							wide
							onClick={closeRestoresDialog}
						>
							Ок, понятно
						</Button>
					</>
				}
			>
				<span className={styles.typography}>
					В целях безопасности Тестник предусматривает лимит на
					количество смен устройства. Вы можете сменить устройство
					входа 12 раз, после чего аккаунт будет временно
					приостановлен. Не передавайте данные своей учетной записи
					третьим лицам.
					<br />
					<br />
					Мы понимаем, что у вас может быть несколько устройств и в
					скором времени откажемся от лимитов.
				</span>
			</RestoresDialog>

			<Card>
				<List className={styles.list}>
					<ListItemButton onClick={openRestoresDialog}>
						<ListItemAvatar className={styles.listItemIcon}>
							<Avatar>
								{(user.email || "?").substr(0, 1).toUpperCase()}
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primaryTypographyProps={{
								className: styles.listItemPrimaryText,
							}}
							secondaryTypographyProps={{
								className: styles.listItemSecondaryText,
							}}
							primary={user.email || "Загрузка..."}
							secondary={
								<>
									<span>Телефон: {user.phone}</span>
									<br />
									<span
										className={
											subscriptionDatesLeft <= 5 &&
											styles.redColor
										}
									>
										Подписка истекает:{" "}
										{format(
											user.expires_at,
											"dd.MM.yyyy 'в' HH:mm"
										)}{" "}
										(через 
										{numberWithSpaces(
											subscriptionDatesLeft
										)}
										 
										{pluralize(
											subscriptionDatesLeft,
											"день",
											"дня",
											"дней"
										)}
										)
									</span>
									<br />
									<span
										className={
											user.restores >= 9 &&
											styles.redColor
										}
									>
										Вы можете сменить устройство еще{" "}
										{12 - user.restores} 
										{pluralize(
											12 - user.restores,
											"раз",
											"раза",
											"раз"
										)}
										.
									</span>
								</>
							}
						/>
					</ListItemButton>

					<Divider className={styles.listDivider} />

					<ListItem>
						<ListItemIcon
							className={classnames(
								styles.listItemIcon,
								styles.graphIcon
							)}
						>
							<TimelineRoundedIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								className: styles.listItemPrimaryText,
							}}
							secondaryTypographyProps={{
								className: styles.listItemSecondaryText,
							}}
							primary="Моя статистика"
							secondary={
								<>
									Это история ваших баллов за сессию в
									Тестнике за последние 7 дней.
									<br />
									<br />
									Пройдено сессий: 
									<span className={styles.highlight}>
										{numberWithSpaces(sessionsPassed)} 
										{pluralize(
											sessionsPassed,
											"сессия",
											"сессии",
											"сессий"
										)}{" "}
									</span>
									<br />
									Всего баллов: 
									<span className={styles.highlight}>
										{numberWithSpaces(weekRating)} 
										{pluralize(
											weekRating,
											"балл",
											"балла",
											"баллов"
										)}
									</span>
									<br />
									Средний балл: 
									<span className={styles.highlight}>
										{numberWithSpaces(weekAverageRating)} 
										{pluralize(
											weekAverageRating,
											"балл",
											"балла",
											"баллов"
										)}
									</span>
								</>
							}
						/>
					</ListItem>
				</List>
				<div className={styles.graph} ref={graphWrapperEl}>
					<ResponsiveContainer
						key={rechartsKey}
						width={graphWidth}
						height="100%"
					>
						<AreaChart
							data={sessionGraph}
							margin={{
								top: 10,
								right: 10,
								bottom: 0,
								left: 0,
							}}
						>
							<defs>
								<linearGradient
									id="colorUv"
									x1="0"
									y1="0"
									x2="0"
									y2="1"
								>
									<stop
										offset="5%"
										stopColor={mainColor}
										stopOpacity={0.6}
									/>
									<stop
										offset="95%"
										stopColor={mainColor}
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>

							<CartesianGrid
								vertical={false}
								strokeDasharray="3 3"
							/>
							<XAxis
								axisLine={false}
								tickLine={false}
								dataKey="date"
								tickSize={15}
								height={30}
								tickFormatter={value =>
									format(
										parse(value, "yyyy-MM-dd", new Date()),
										"dd"
									)
								}
								style={{
									fontSize: "12px",
									fill: "rgb(122, 122, 122)",
								}}
							/>
							<YAxis
								axisLine={false}
								tickLine={false}
								width={58}
								tickSize={8}
								tickCount={5}
								minTickGap={1}
								ticks={
									hasAnyGraphData
										? undefined
										: [0, 20, 40, 60, 80, 100]
								}
								tickFormatter={value => numberWithSpaces(value)}
								style={{
									fontSize: "12px",
									fill: "rgb(122, 122, 122)",
								}}
								//interval="preserveStartEnd"
							/>
							<Tooltip
								content={({active, payload, label}) =>
									active && (
										<div className={styles.tooltip}>
											<div className={styles.total}>
												{numberWithSpaces(
													payload[0].value
												)}
												 баллов
											</div>
											<div className={styles.date}>
												за{" "}
												{format(
													parse(
														label,
														"yyyy-MM-dd",
														new Date()
													),
													"d MMMM",
													{locale: ru}
												)}
											</div>
										</div>
									)
								}
								offset={20}
							/>
							<Area
								type="monotone"
								dataKey="total"
								strokeWidth={3}
								stroke={mainColor}
								fillOpacity={1}
								fill="url(#colorUv)"
								onMouseEnter={onGraphMouseEnter}
								//dot={{strokeDasharray: ""}}
								activeDot={{
									fill: "white",
									stroke: mainColor,
									strokeWidth: 3,
									r: 5,
								}}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
				<List className={styles.list}>
					{list.map(item => {
						const {
							title,
							subtitle,
							icon: Icon,
							type,
							url,
							...rest
						} = item
						const Content = () => (
							<ListItemButton {...rest}>
								<ListItemIcon className={styles.listItemIcon}>
									<item.icon />
								</ListItemIcon>
								<ListItemText
									primaryTypographyProps={{
										className: styles.listItemPrimaryText,
									}}
									secondaryTypographyProps={{
										className: styles.listItemSecondaryText,
									}}
									primary={title}
									secondary={subtitle}
								/>
							</ListItemButton>
						)

						const Wrapper = {
							"external-link": props => (
								<Link
									to={props.url}
									block
									external
									children={props.children}
								/>
							),
							"internal-link": props => (
								<Link
									to={props.url}
									block
									internal
									children={props.children}
								/>
							),
							button: props => <>{props.children}</>,
						}[type]

						return (
							<Fragment key={item.title}>
								<Divider className={styles.listDivider} />
								<Wrapper url={url}>
									<Content />
								</Wrapper>
							</Fragment>
						)
					})}
					{proxies.length > 0 && (
						<>
							<Divider className={styles.listDivider} />
							<ListItem>
								<ListItemIcon className={styles.listItemIcon}>
									<DnsRoundedIcon />
								</ListItemIcon>
								<ListItemText
									primaryTypographyProps={{
										className: styles.listItemPrimaryText,
									}}
									secondaryTypographyProps={{
										className: styles.listItemSecondaryText,
									}}
									primary={"Прокси серверы"}
									secondary={
										<>
											Если университет блокирует доступ к
											Тестнику, воспользуйтесь прокси
											сервером
											<br />
											<span
												className={styles.proxiesList}
											>
												{proxies.map(item => (
													<Fragment key={item}>
														<span>
															•{" "}
															<Link
																to={item}
																external
																underline={
																	"always"
																}
															>
																{item.replace(
																	/^https:\/\/|\/$/g,
																	""
																)}
															</Link>
														</span>
														<br />
													</Fragment>
												))}
											</span>
										</>
									}
								/>
							</ListItem>
						</>
					)}
				</List>
			</Card>
		</>
	)
}

export default App
