import {configureStore} from "@reduxjs/toolkit"
import authSlice from "./auth-slice"
import universitiesSlice from "./universities-slice"

export default configureStore({
	reducer: {
		isAuthorized: authSlice,
		universities: universitiesSlice,
	},
})
